<template>
  <div class="info">
    <div>
      <div class="title">网站概况</div>
      <div class="company">
        <div class="left shadow">
          <div class="right-top">
            <span :class="projectVal.endTime - new Date().getTime() < 0 ? 'red' : ''">{{ projectVal.endTime - new Date().getTime() < 0 ? '已到期' : '正常' }}</span>
          </div>
          <div class="cen">
            <span class="name">网站名称：{{ logo.title || '未命名' }}</span>
            <span :class="projectVal.endTime - new Date().getTime() < 0 ? 'website red' : 'website blue'">到期时间：{{ projectVal.endTime ? shijianc(projectVal.endTime) : '永久' }}（{{ projectVal.endTime ? '剩 ' + shijianc(projectVal.endTime, 'day') + ' 天' : '无限制' }}）</span>
            <span class="website mt10 cur" @click="goUrl(viewUrl)">二级网站域名：{{ viewUrl }}</span>
            <div class="button">
              <div class="design" @click="$router.push('/mall/pages-manage')">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675307927092ce.png" alt="" />
                <span>设计网站</span>
              </div>
              <div class="more" @click="$router.push('/mall/configure/contact')">
                <span>编辑名称</span>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675307920657ce.png" alt="" />
              </div>
            </div>
            <div class="share">
              <div @click="goUrl(viewUrl)">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675308525866ce.png" alt="" />
                <span>电脑访问</span>
              </div>
              <div @click="goUrl(viewUrl)">
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675308531749ce.png" alt="" />
                <span>手机访问</span>
              </div>
              <!-- <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675308536599ce.png" alt="" />
                <span>分享</span>
              </div> -->
            </div>
          </div>
          <div class="list">
            <div class="item" @click="$router.push('/mall/home/domain')">
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675309109354ce.png" alt="" />
              <div>
                <span class="name">域名管理</span>
                <span class="website">绑定独立域名</span>
              </div>
            </div>
            <div class="item" @click="msg('已是最优节点')">
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675309114154ce.png" alt="" />
              <div>
                <span class="name">切换节点</span>
                <span class="website">全球节点</span>
              </div>
            </div>
            <div class="item" @click="msg('已是最新版本')">
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675309121548ce.png" alt="" />
              <div>
                <span class="name">网站升级</span>
                <span class="website">最新版本</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="try shadow">
            <div class="tit" @click="$router.push('/mall/configure/contact')">
              <span class="name">{{ logo.title || '未命名' }}</span>
              <span class="shi">普</span>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675316916433ce.png" alt="" />
            </div>
            <!-- <div class="com" @click="goUrl(viewUrl)">
              <span>⊙访问网站</span>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675316932996ce.png" alt="" />
            </div> -->
            <div class="text">
              <span @click="$router.push('/mall/home/domain')">绑定独立域名</span>
              <span @click="$router.push('/mall/home/domain')">已绑定域名</span>
            </div>
            <div class="go" @click="$router.push('/mall/pages-manage')">
              <div class="txt">
                <div class="top">
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675316967077ce.png" alt="" />
                  <span>电脑网站</span>
                </div>
                <div class="but">
                  <span>去设计</span>
                  <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675316976749ce.png" alt="" />
                </div>
              </div>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675316985474ce.png" alt="" />
            </div>
          </div>
          <div class="type shadow">
            <span>您有任何问题都可以联系我们</span>
            <div>
              <span @click="contact('tel')">联系方式</span>
              <span class="line" @click="contact('qq')">在线咨询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="types">
        <div class="left shadow">
          <div class="tit">快捷入口</div>
          <div class="list">
            <div class="item" @click="$router.push('/mall/article/category')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319556997ce.png" alt="" />
              </div>
              <span>资讯管理</span>
            </div>
            <div class="item" @click="$router.push('/mall/commodity/product-category')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319552676ce.png" alt="" />
              </div>
              <span>产品管理</span>
            </div>
            <div class="item" @click="$router.push('/mall/sale/sale-category')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319566709ce.png" alt="" />
              </div>
              <span>售前售后</span>
            </div>
            <div class="item" @click="$router.push('/mall/video/video-category')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319774571ce.png" alt="" />
              </div>
              <span>培训管理</span>
            </div>
            <div class="item" @click="$router.push('/mall/feedback/feedbackList')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319561924ce.png" alt="" />
              </div>
              <span>加盟管理</span>
            </div>
            <div class="item" @click="$router.push('/mall/content/notice')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319572451ce.png" alt="" />
              </div>
              <span>内容管理</span>
            </div>

            <div class="item" @click="$router.push('/mall/advert/header')">
              <div>
                <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319607029ce.png" alt="" />
              </div>
              <span>导航管理</span>
            </div>
          </div>
        </div>
        <div class="right shadow">
          <div class="tit">关于我们</div>
          <div class="look">
            <div class="img">
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319596414ce.png" alt="" />
            </div>
            <div class="text">
              <span class="name">公司信息</span>
              <span class="txt">设置公司相关信息与内容</span>
            </div>
            <div class="go" @click="$router.push('/mall/team/introduce')">
              <span>去设置</span>
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675319792390ce.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="statistics shadow">
        <div>
          <div class="tit">
            <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1675321326182ce.png" alt="" />
            <span class="txt">今日访问统计</span>
            <!-- <span class="details">详情</span> -->
          </div>
          <div class="list">
            <div>
              <span class="num">0</span>
              <span class="text">访客UV</span>
            </div>
            <div>
              <span class="num">0</span>
              <span class="text">浏览量PV</span>
            </div>
            <div>
              <span class="num">0</span>
              <span class="text">浏览量PV</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="联系我们：" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <div>您是我们的尊贵客户，有任何问题可联系我们！</div>
        <br />
        <br />
        <br />
        <div>联系电话：{{ setting.contact.phone }}</div>
        <br />
        <div>QQ号：{{ setting.contact.phone }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/cloud'
export default {
  data() {
    return {
      viewUrl: '',
      logo: '',
      projectVal: '',
      setting: {
        contact: {}
      },
      dialogVisible: false
    }
  },
  mounted() {
    this.getText()
  },
  methods: {
    contact(val) {
      this.dialogVisible = true
    },
    // 年-月-日
    shijianc(time, value) {
      var date = new Date(time)
      var nowTime = +new Date()
      if (value == 'day') {
        if (time - nowTime < 0) {
          return 0
        } else {
          return Math.floor((time - nowTime) / (1000 * 3600 * 24))
        }
      }
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D
    },
    goUrl(url) {
      window.open(url, '_blank')
    },
    msg(val) {
      this.$message({
        showClose: true,
        message: val,
        type: 'success'
      })
    },
    getText() {
      const project = localStorage.getItem('mall-project')
      this.projectVal = JSON.parse(project)
      if (this.projectVal.endTime) {
        if (this.projectVal.endTime - new Date().getTime() < 0) {
          this.$confirm('该项目已过期,请联系管理员续费！', '到期提醒', {
            confirmButtonText: '知道了',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {})
            .catch(() => {})
        }
      }
      const id = JSON.parse(project)._id
      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSystem'
        })
        .then(res => {
          if (res) {
            this.setting = res
            let url = ''
            if (process.env.NODE_ENV == 'production') {
              url = res.viewUrl
            } else {
              url = 'http://localhost:9988'
            }
            this.viewUrl = url + '/pages/index/tabbar/home?projectId=' + id
          }
        })

      http
        .POST('/jw-admin', {
          module: 'settings',
          operation: 'getSettings',
		  project: id
        })
        .then(res => {
          if (res) {
            if (res.logo) this.logo = res.logo
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  width: 100%;
  background: #f6f9fc;
  box-sizing: border-box;
  padding: 10px;
  .title {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .shadow {
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(105, 161, 231, 0.16);
    border-radius: 5px;
  }
  .company {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      width: 70%;
      height: 435px;
      display: flex;
      flex-direction: column;
      .right-top {
        display: flex;
        justify-content: flex-end;
        .red {
          background: red;
        }
        span {
          width: 60px;
          height: 28px;
          background: #008aff;
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 23px 24px 0 0;
        }
      }
      .cen {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 45px;
        .red {
          color: red !important;
        }
        .blue {
          color: #008aff !important;
        }
        .name {
          font-size: 30px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 49px;
        }
        .cur {
          cursor: pointer;
        }
        .website {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
        .button {
          display: flex;
          margin-top: 26px;
          > div {
            width: 140px;
            height: 49px;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
              width: 16px;
              height: 16px;
            }
            > span {
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 22px;
            }
          }
          .design {
            cursor: pointer;
            background: #56c22d;
            margin-right: 33px;
            > span {
              color: #ffffff;
              margin-left: 5px;
            }
          }
          .more {
            cursor: pointer;
            background: #f7f7f7;
            > span {
              color: #666666;
              margin-right: 5px;
            }
          }
        }
        .share {
          width: 50%;
          display: flex;
          margin-top: 53px;
          justify-content: space-evenly;
          > div {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
            }
            span {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      .list {
        flex: 1;
        display: flex;
        margin-top: 34px;
        border-top: 1px solid #e0e0e0;
        .item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e0e0e0;
          cursor: pointer;
          &:last-child {
            border: none;
          }
          img {
            width: 50px;
            height: 50px;
            margin-right: 14px;
          }
          > div {
            display: flex;
            flex-direction: column;
            .name {
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #666666;
              line-height: 22px;
            }
            .website {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
              margin-top: 3px;
            }
          }
        }
      }
    }
    .right {
      width: 28%;
      display: flex;
      flex-direction: column;
      .try {
        margin-bottom: 10px;
        padding: 22px 19px 13px;
        .tit {
          display: flex;
          align-items: center;
          cursor: pointer;
          .name {
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 25px;
          }
          .shi {
            width: 16px;
            height: 16px;
            background: #56c22d;
            border-radius: 3px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            margin-left: 10px;
          }
          img {
            width: 13px;
            height: 13px;
            margin-right: 5px;
          }
        }
        .com {
          display: flex;
          align-items: center;
          margin-top: 5px;
          cursor: pointer;
          span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #666666;
            line-height: 20px;
          }
          img {
            width: 14px;
            height: 14px;
            margin-left: 10px;
          }
        }
        .text {
          display: flex;
          margin-top: 20px;
          span {
            cursor: pointer;
            height: 32px;
            background: #eef7ff;
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            padding: 0 17px;
            display: flex;
            align-items: center;
            margin-right: 13px;
          }
        }
        .go {
          cursor: pointer;
          width: 100%;
          height: 135px;
          background: #638efa;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-top: 15px;
          position: relative;
          .txt {
            margin-left: 30px;
            .top {
              display: flex;
              align-items: center;
              img {
                width: 22px;
                height: 22px;
                margin-right: 5px;
              }
              span {
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 25px;
              }
            }
            .but {
              width: 96px;
              height: 26px;
              background: #ffffff;
              border-radius: 13px;
              margin-top: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #638efa;
              }
              img {
                width: 12px;
                height: 12px;
                margin-left: 1px;
              }
            }
          }
          > img {
            width: 160px;
            height: 127px;
            position: absolute;
            bottom: 0;
            right: 18px;
          }
        }
      }
      .type {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 17px;
          margin-bottom: 22px;
        }
        > div {
          display: flex;
          span {
            height: 32px;
            background: #56c22d;
            border-radius: 16px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            padding: 0 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .line {
            background: #ffaf14;
            margin-left: 22px;
          }
        }
      }
    }
  }
  .types {
    width: 100%;
    height: 190px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      padding: 0 48px;
      .list {
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        cursor: pointer;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          > div {
            width: 55px;
            height: 55px;
            background: linear-gradient(145deg, #ffffff 0%, #93c6ff 100%);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 35px;
              height: 35px;
            }
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-top: 12px;
          }
        }
      }
    }
    .right {
      width: 37%;
      padding: 0 36px;
      .look {
        display: flex;
        align-items: center;
        margin-top: 18px;
        .img {
          width: 55px;
          height: 55px;
          background: linear-gradient(145deg, #ffffff 0%, #93c6ff 100%);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px;
          img {
            width: 35px;
            height: 35px;
          }
        }
        .text {
          flex: 1;
          display: flex;
          flex-direction: column;

          .name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
          .txt {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            margin-top: 2px;
          }
        }
        .go {
          height: 25px;
          background: #eef5ff;
          border-radius: 13px;
          padding: 0 11px;
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 17px;
            margin-right: 2px;
          }
          img {
            width: 12px;
            height: 13px;
          }
        }
      }
    }
    .tit {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-top: 28px;
    }
  }
  .statistics {
    width: 100%;
    height: 320px;
    padding: 30px 20px;
    margin-top: 10px;
    > div {
      width: 100%;
      height: 190px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(105, 161, 231, 0.16);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .tit {
        display: flex;
        align-items: center;
        padding: 26px 35px 0;
        > img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .txt {
          flex: 1;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .details {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #56c22d;
          line-height: 22px;
        }
      }
      .list {
        flex: 1;
        margin-top: 21px;
        border-top: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-around;
        align-items: center;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          .num {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 42px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
